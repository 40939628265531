import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import { LineHeader, Title, Semi } from "components/Module/Module";
import TriggerSolidNav from "components/TriggerSolidNav/TriggerSolidNav";
import PageHeader from "modules/PageHeader/PageHeader";
import { PadSection } from "components/Section/Section";
import Grid from "components/Grid/Grid";
import {
  pageBuilder as Builder,
  sectionBuilder as SectionBuilder
} from "modules/Editorial/Editorial";
import GraphQLErrorList from "components/graphql-error-list";
import SEO from "components/seo";
import Layout from "containers/layout";

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    about: sanityAbout {
      heroBanner {
        desktopFeatured {
          alt
          caption
          theme
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        mobileFeatured {
          alt
          caption
          theme
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      pageBuilder {
        ... on SanityEditorialPullQuoteParagraph {
          pQuote: quote {
            _key
            _type
            column
            quote
          }
          _key
          _type
          paragraphs {
            _key
            _type
            _rawContent
          }
        }
        ... on SanityEditorialImage1 {
          _key
          _type
          image {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          column
        }
        ... on SanityEditorialImage2 {
          _key
          _type
          images {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
            alt
            caption
          }
        }
        ... on SanityEditorialParagraph {
          _key
          _type
          _rawContent
          column
        }
        ... on SanityEditorialQuote {
          _key
          _type
          column
          quote
        }
        ... on SanityEditorialImage3 {
          _key
          _type
          image {
            caption
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        ... on SanityEditorialImage4 {
          _key
          _type
          image {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      sectionBuilder {
        ... on SanitySectionColumnSection {
          _key
          _type
          title
          columnTwo {
            _rawContent
          }
          columnOne {
            _rawContent
          }
        }
        ... on SanitySectionImage {
          _key
          _type
          title
          image {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          video {
            url
            muted
            loop
            controls
            autoPlay
          }
        }
      }
    }
  }
`;

const AboutPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const about = (data || {}).about;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }


  return (
    <Layout theme="dark">
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <TriggerSolidNav>
        <PageHeader
          image={about.heroBanner.desktopFeatured ? about.heroBanner.desktopFeatured.asset : null}
          mobileImage={about.heroBanner.mobileFeatured ? about.heroBanner.mobileFeatured.asset : null}
          alt={about.heroBanner.desktopFeatured ? about.heroBanner.desktopFeatured.alt : null}
          theme={about.heroBanner.desktopFeatured ? about.heroBanner.desktopFeatured.theme : null}
          title={<>the new moon <Semi>about</Semi></>}
        />
      </TriggerSolidNav>
      {Builder(about.pageBuilder)}
      {SectionBuilder(about.sectionBuilder)}
    </Layout>
  );
};

export default AboutPage;
