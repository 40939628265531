import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Header, Title } from "components/Module/Module";
import { PadSection } from "components/Section/Section";
import { media } from "utils/mediaQuery";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 75vh;

  :after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%);
  }
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: flex-end;
`;

const Image = styled(GatsbyImage)`
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const HeroDesktop = styled(Image)`
  display: none;
  ${media.large`
    display: block;
  `}
`;

const HeroMobile = styled(Image)`
  display: block;
  ${media.large`
    display: none;
  `}
`;

const HeroA = ({ theme, image, mobileImage, alt, title, ...rest }) => {
  const imageObject = image ? getImage(image) : null;
  const imageMobileObject = mobileImage ? getImage(mobileImage) : null;

  return (
    <Wrapper {...rest}>
      <Content>
        <PadSection>
          <Header>
            <Title theme={theme}>
              {title}
            </Title>
          </Header>
        </PadSection>
      </Content>
      {imageObject && (
        <HeroDesktop image={imageObject} objectFit="cover" objectPosition="50% 50%" alt={alt} />
      )}
      {imageMobileObject && (
        <HeroMobile image={imageMobileObject} objectFit="cover" objectPosition="50% 50%" alt={alt}/>
      )}
    </Wrapper>
  );
};

export default HeroA;
